import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_vm.$store.state.userInfo.company ? _c('div', {
    staticClass: "user-div"
  }, [_c('div', {
    staticClass: "user"
  }, [_c('img', {
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-14.png"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$store.state.userInfo.phone))])]), _c('div', {
    staticClass: "right"
  }, [_c('div', {
    staticClass: "integral"
  }, [_vm._v("可用积分：" + _vm._s(_vm.$store.state.integralCurrency.integral))])])]) : _vm._e(), _vm.topShopData.id ? _c('div', {
    staticClass: "banner-item",
    on: {
      "click": function ($event) {
        return _vm.$router.push(`/goodDetail/${_vm.topShopData.id}`);
      }
    }
  }, [_c('div', {
    staticClass: "cover"
  }, [_c('van-image', {
    attrs: {
      "width": "100%",
      "height": "100%",
      "fit": "cover",
      "src": _vm.topShopData.cover
    }
  })], 1), _c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.topShopData.name))]), _c('div', {
    staticClass: "bottom-div"
  }, [_c('div', {
    staticClass: "price"
  }, [_vm._v(_vm._s(_vm.topShopData.integral ? `${_vm.topShopData.integral}积分` : '') + _vm._s(_vm.topShopData.integral && _vm.topShopData.price ? '+' : '') + _vm._s(_vm.topShopData.price ? `${_vm.topShopData.price}元` : ''))]), _vm._m(0)])]) : _vm._e(), _c('div', {
    staticClass: "integral-nav-div"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("分值预览")]), _vm._l(_vm.integralList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "li",
      class: {
        'cur': _vm.integralIndex == index
      },
      on: {
        "click": function ($event) {
          return _vm.selectIntegral(index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.min + '-' + item.max) + " ")]);
  })], 2), _c('parent-list-content', {
    ref: "parentListContent",
    on: {
      "onDataLoad": _vm.onDataLoad,
      "pushData": _vm.pushData
    }
  }, [_c('div', {
    staticClass: "good-div"
  }, [_c('good-item-comp', {
    attrs: {
      "itemList": _vm.itemList
    }
  })], 1)]), _c('tabbar-comp', {
    attrs: {
      "index": 1
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "but"
  }, [_vm._v(" 兑换"), _c('img', {
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-17.png"
    }
  })]);
}];
export { render, staticRenderFns };