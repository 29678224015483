export default {
  name: 'shop',
  components: {},
  data() {
    return {
      integralIndex: -1,
      integralList: [{
        min: 0,
        max: 10000
      }, {
        min: 10000,
        max: 20000
      }, {
        min: 20000,
        max: 30000
      }, {
        min: 30000,
        max: 40000
      }, {
        min: 40000,
        max: 50000
      }],
      itemList: [],
      topShopData: {}
    };
  },
  async created() {
    this.getTopShop();
  },
  async mounted() {},
  methods: {
    async getTopShop() {
      let result = await this.$request({
        url: '/app/api/shop/good/top',
        method: 'get'
      });
      this.topShopData = result.data;
    },
    selectIntegral(index) {
      this.integralIndex = index;
      this.$refs.parentListContent.reload();
      // reload
    },

    onDataLoad: async function (options, callback) {
      if (this.integralIndex >= 0) {
        options.start = this.integralList[this.integralIndex].min;
        options.end = this.integralList[this.integralIndex].max;
      }
      const result = await this.$request({
        url: '/app/api/shop/goods',
        method: 'get',
        params: options
      });
      callback(result);
    },
    pushData(items, type) {
      console.log(items);
      console.log(type);
      if (type === 'set') this.itemList = items;
      if (type === 'add') this.itemList = this.itemList.concat(items);
      // this.itemList = items
    }
  }
};